import React, { useState, useEffect } from "react";
import Banner_1 from "../assets/Banners/Banner_1.webp";
import Banner_2 from "../assets/Banners/Banner_2.webp";
import Banner_3 from "../assets/Banners/Banner_3.webp";
import Banner_4 from "../assets/Banners/Banner_4.webp";
import Banner_5 from "../assets/Banners/Banner_5.webp";
import Banner_6 from "../assets/Banners/Banner_6.webp";
import Banner_7 from "../assets/Banners/Banner_7.webp";

const imageUrls = [Banner_1, Banner_2, Banner_3, Banner_4, Banner_5, Banner_6, Banner_7];

const Banner = () => {
  const [backgroundIndex, setBackgroundIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBackgroundIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 3000); // Change background every 5 seconds (adjust as needed)

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="relative bg-cover bg-center">
       <div 
    // data-scroll data-scroll-section data-scroll-speed=".1"
      className="md:relative h-[90vw] md:h-[36vw] sticky top-0 bg-cover bg-center flex justify-center items-center lg:items-end lg:pb-52 p-5"
      style={{ backgroundImage: `url(${imageUrls[backgroundIndex]})` }}
    >
        </div>
        {/* <div className="bg-[#EDEDEB] md:w-[30%] rounded-2xl md:absolute md:top-1/2  md:right-20 md:transform md:-translate-y-1/2 py-10 md:px-10">
    <h2 className="text-2xl md:text-3xl tracking-wide p-2  font-times text-center">Enquiry Form</h2>
    
      <iframe
          title="Contact form"
          className="text-3xl w-full md:text-4xl h-[280px] md:h-[22vw] text-white rounded-2xl font-semibold font-serif"
          id="Contact_Form"
          src="https://account.solidperformers.com/capture_form_data/OTM3"
          border="0"
          style={{ border: 0 }}
        ></iframe>
        
  </div> */}
      
        {/* <TypeAnimation className=" text-yellow-200 text-center  text-2xl md:text-4xl font-extrabold font-times"
        sequence={[
          `"15 Years of Excellence: A Legacy of Success, A Journey of Achievements"`,
          10000,
          "",
        ]}
        speed={10}
        style={{ whiteSpace: 'pre-line'}}
        repeat={Infinity}
      /> */}
     
  
    </div>
   
  );
};

export default Banner;