import React from "react";
// import bg from "../../assets/photos/bg_image3.png"
// import bg3 from "./Background_img/bg3.jpg";
// import img_g from "./Card_img/Card_7.jpg";
import img_h from "./Card_img/Card_8.jpg";

function Project3_Trading() {
  return (
    <div>
       {/* data-scroll data-scroll-section data-scroll-speed=".1" */}
      <h2
        // style={{ backgroundImage: `url(${bg3})` }}
        className="text-2xl md:text-4xl font-berkshire p-3 text-center bg-cover"
      >
        Land Trading & Collaboration
      </h2>

      {/* (Project Component-2) */}
      <div className="flex flex-col">
      <section className="grid gap-5 m-auto p-3 md:gap-20">
        {/* ....(3.1)... */}
        {/* <a href="http://www.nirmanamrealtors.com" target="_blank" rel="noreferrer">
            <div className="w-fit lg:size-[26vw] rounded-xl hover:shadow-lg hover:bg-[#E0BF8D] hover:text-[#08392F] bg-[#08392F] text-[#E0BF8D] md:m-3 shadow-xl shadow-#E0BF8D md:hover:scale-90 duration-500">
          <img className="rounded-t-xl" src={img_g} alt="Card4" />

          <div className="mx-auto p-3 flex flex-col gap-3">
            <h2 className=" text-2xl font-[yantramanav] font-semibold">
              Niramanam Realtors
            </h2>
            <p className="text-xl md:text-2xl font-[yantramanav]">
              Delhi NCR
            </p>
             <a href="http://www.nirmanamrealtors.com" target="_blank" rel="noreferrer">
              <button className="border-[#E0BF8D] hover:border-[#08392F] border-2 p-1 rounded-md text-sm md:text-lg">
                www.nirmanamrealtors.com
              </button>
            </a>
          </div>

          {/* <div className="mx-auto p-3">
            <p className="text-sm md:text-lg font-semibold font-[yantramanav]">
              Visit Our Website
            </p>
           
          </div> *
        </div>
        </a> */}

      

        {/* ....(3.2).... */}
        <a
            href="http://www.shapemyhouse.com"
            target="_blank"
            rel="noreferrer"
            className="w-full"
          >
            <div className="w-full md:h-96 md:w-80 rounded-xl hover:shadow-lg hover:bg-[#E0BF8D] hover:text-[#08392F] bg-[#08392F] text-[#E0BF8D] shadow-xl transform transition-transform duration-500 hover:scale-95">
              <img
                className="rounded-t-xl w-full h-48 object-cover"
                src={img_h}
                alt="Card5"
              />
              <div className="mx-auto p-3 pt-5 md:pt-10 flex flex-col gap-3">
                <h2 className="text-xl md:text-2xl font-[yantramanav] font-semibold">
                  Shape My House
                </h2>
                <p className="text-lg md:text-xl font-[yantramanav]">
                  Delhi NCR
                </p>
                <a
                  href="http://www.shapemyhouse.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="border-[#E0BF8D] hover:border-[#08392F] border-2 p-1 rounded-md text-sm md:text-lg">
                    www.shapemyhouse.com
                  </button>
                </a>
          </div>
        </div>
        </a>

       

        {/* ....(3.3)...... */}
{/* 
        <div className="w-fit md:w-96 rounded-xl border-2 bg-red-50 border-red-200 shadow-lg md:m-3 shadow-slate-200">
          <img className="rounded-t-xl" src={img_h} alt="Card6" />

          <div className="mx-auto my-4 p-3">
            <h2 className=" text-3xl text-zinc-700 font-[yantramanav] font-semibold">
              Nest Tower
            </h2>
            <p className="text-xl md:text-2xl font-semibold font-[yantramanav]">
              Okhla Industrial Area, Delhi
            </p>
          </div>

          <div className="mx-auto p-3">
            <p className="text-sm md:text-lg font-semibold font-[yantramanav]">
              Visit Our Website
            </p>
            <a href="https://www.shapemyhouse.com/" target="_blank">
               <button className="bg-green-900 text-white p-1 rounded-xl text-sm md:text-lg hover:text-blue-400">
                www.shapemyhouse.com
              </button>
            </a>
          </div>
        </div> */}
      </section>
      </div>
      
      
    </div>
  );
}

export default Project3_Trading;
