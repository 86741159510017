import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
// import TSGOC_LOGO_F_8 from '../assets/images/brand_logo7.png';
// import TSGOC_LOGO_F_8 from '../assets/logos/THE SHUBHUM GROUP  123 sh.png';
import TSGOC_LOGO_F_8 from '../assets/logos/logo.png';



function Header() {
    const [toggle, setToggle] = useState(false);
    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // bg-${scrolling ? '[black]' : 'black'}
    return (
        // <header className={`fixed top-0 z-50 w-full bg-${scrolling ? 'black' : 'transparent'} bg-opacity-${scrolling ? '70' : '100'}`}>
        <header className={`w-full sticky top-0 z-50 bg-[#04362D] md:pr-5  bg-opacity-${scrolling ? '80' : '85'}`}>
            <div className='w-full flex flex-row justify-between p-3 h-[120px] items-center'>
                <div className='text-3xl font-bold'>
                    <Link onClick={() => { setToggle(!toggle) }} to='/'>
                        {/* <img className='w-36 md:w-52 pb-1 md:px-4 rounded-full' src={TSGOC_LOGO_F_8} alt='logo' /> */}
                        <img className='w-36' src={TSGOC_LOGO_F_8} alt='logo' />

                    </Link>
                </div>
                {
                    toggle ?
                        <AiOutlineClose onClick={() => setToggle(!toggle)} className='md:hidden block text-white text-2xl mx-4' />
                        :
                        <AiOutlineMenu onClick={() => setToggle(!toggle)} className='md:hidden block text-white text-2xl mx-4' />
                }
                <div className='hidden md:flex md:text-xl lg:text-2xl flex-row text-[#E0BF8D] font-[rating] md:gap-5 lg:gap-10'>
                    <Link className='hover:scale-110 duration-500 hover:text-yellow-400 ' to='/'>Home</Link>
                    <Link className='hover:scale-110 duration-500 hover:text-yellow-400 ' to='/about'>About Us</Link>
                    <Link className='hover:scale-110 duration-500 hover:text-yellow-400 ' to='/our Projects'>Our Projects</Link>
                    <Link className='hover:scale-110 duration-500 hover:text-yellow-400 ' to='/investors'>Investors</Link>
                    <Link className='hover:scale-110 duration-500 hover:text-yellow-400 ' to='/associates'>Associates</Link>
                    <Link className='hover:scale-110 duration-500 hover:text-yellow-400 ' to='/contact'>Reach Us</Link>
                </div>
                <div className={`md:hidden flex flex-col text-white fixed bg-black bg-opacity-65 top-[120px] w-full h-screen ${toggle ? 'left-0' : 'left-[-100%]'} duration-1000`}>
                    <Link onClick={() => { setToggle(!toggle) }} to='/' className='p-5'>Home</Link>
                    <Link onClick={() => { setToggle(!toggle) }} to='/about' className='p-5'>About</Link>
                    <Link onClick={() => { setToggle(!toggle) }} to='/our projects' className='p-5'>Our Projects</Link>
                    <Link onClick={() => { setToggle(!toggle) }} to='/investors' className='p-5'>Investors</Link>
                    <Link onClick={() => { setToggle(!toggle) }} to='/associates' className='p-5'>Associates</Link>
                    <Link onClick={() => { setToggle(!toggle) }} to='/contact' className='p-5'>Reach Us</Link>
                </div>
            </div>
        </header>
    )
}

export default Header;
