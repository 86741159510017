import React from "react";
// import bg from "../../assets/photos/TSGOC_Website-page1.jpg";
import Project1Residential from "./Project1_Residential";
import Project2Resorts from "./Project2_Resorts";
import Project3Trading from "./Project3_Trading";
import ProjectDelivered from "./ProjectDelivered";

function Our_Projects() {
  return (
    <div
      className="bg-[#E0BF8D] bg-cover bg-center bg-opacity-75 py-10"
      // data-scroll data-scroll-section data-scroll-speed="-.1"
      // style={{ backgroundImage: `url(${bg})` }}
    >
      <h4 className="text-3xl md:text-5xl md:pt-5 text-center font-berkshire">
        Explore Our Projects
      </h4>
  

      {/* (Project Heading Part.1).. */}
      <Project1Residential />

      <div className="flex flex-col py-10 gap-10">
        {/* (Project heading.2).. */}
        <Project2Resorts />
        {/* (Project heading.3).. */}
        <Project3Trading />
      </div>
          {/* Delivered Projects */}
          <ProjectDelivered />
    </div>
  );
}

export default Our_Projects;
