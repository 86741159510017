import React from "react";
// import location from "./icons2/location.gif";
import TSGOC_LOGO_F_8 from "./icons2/TSGOC_LOGO_F_8.png";
import message from "./icons2/message.gif";
import phone from "./icons2/phone.gif";

import icon_facebook from "./icons2/icon_facebook.png";
import icon_twitter from "./icons2/icon_twitter.png";
import icon_insta from "./icons2/icon_insta.png";
import icon_yt from "./icons2/icon_yt.png";
import icon_linkdin from "./icons2/icon_linkdin.png";

function ContactAddress() {
  const phoneNumber = "8510850101";
  return (
    <section className="w-full md:w-1/2 m-3 p-5 lg:px-20 rounded-md">
      <div className="flex flex-col gap-10 ">
        <div>
          <h2 className="text-3xl md:text-4xl px-3 font-serif font-bold">
            Reach out to us..
          </h2>
        </div>

        <div className="flex flex-col gap-3 p-3">
          {/* <div>
            <a href="/">
              <img
                className="w-44 h-14 pb-1 bg-green-800"
                src={TSGOC_LOGO_F_8}
                alt="logo"
              />
            </a>
          </div> */}

          <div className="flex gap-3 items-center">
            <a href="mailto:info@tsgoc.in">
              <img
                className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300"
                src={message}
                alt="message"
              />
            </a>
            <a
              className="text-xl font-semibold p-1 hover:text-blue-400 "
              href="mailto:info@tsgoc.in"
            >
              {"info@tsgoc.in "}
            </a>
          </div>

          <div className="flex gap-3 items-center">
            <a href={`tel:${phoneNumber}`}>
              <img
                className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300"
                src={phone}
                alt="message"
              />
            </a>
            <a href={`tel:${phoneNumber}`} className="text-xl font-semibold hover:text-yellow-300 hover:bg-black p-1">+91 85108 50101</a>
            {/* <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> */}
          </div>
        </div>

        <div className="flex flex-col">
          <p className="text-2xl md:text-3xl font-serif m-3">
            Connect with us:
          </p>

          <div className="flex w-fit gap-8 m-3">
            <a
                href="https://www.facebook.com/theshubhamGroup/"
                target="_blank"
              >
                <img
                  className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300"
                  src={icon_facebook}
                  alt="Facebook Link.."
                />
              </a>

            <a href="https://twitter.com/theshubhamgroup" target="_blank">
                <img
                  className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300"
                  src={icon_twitter}
                  alt="Twitter Link.."
                />
              </a>

            <a
                href="https://www.instagram.com/theshubhamgroup/"
                target="_blank"
              >
                <img
                  className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300"
                  src={icon_insta}
                  alt="Insta Link.."
                />
              </a>

            <a
              href="https://www.youtube.com/@theshubhamgroup736"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-10 h-fit p-1 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
                src={icon_yt}
                alt="Youtube Link.."
              />
            </a>

            <a
              href="https://www.linkedin.com/in/the-shubham-group-of-company/"
              target="_blank"
            >
              <img
                className="w-10 h-fit p-1 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-slate-300"
                src={icon_linkdin}
                alt="Linkdin Link.."
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactAddress;
