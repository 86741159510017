import React, { useEffect, useRef } from 'react';
import Banner from "../Banner";
// import Contact from "./Contact";
// import Gallery from "./Gallery";
import About from "./About";
import MainHeading from "../MainHeading";
import CompanyParts from "./CompanyParts";
// import Gallery2 from "./Gallery2";
// import bg from "../../assets/photos/bg_image3.png"
// import Marquee from "./Marquee";
// import img from "../../assets/photos/Logos.banner..3.jpg"
import img from "../../assets/photos/logoBanner.png"

 import LocomotiveScroll from 'locomotive-scroll';
import OurProjects from './Our_Projects';

function Home() {
  const containerRef = useRef(null);

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: containerRef.current,
      // Add any options you need here
    });
    return () => {
      scroll.destroy();
    };
  }, []);

  return (
    <div className="bg-[#f8f6f3]" ref={containerRef}>
      <Banner />
      <MainHeading/>
      <div className="bg-contain bg-center flex flex-col gap-3 md:gap-10 md:p-5 pt-0 w-full"
      // style={{ backgroundImage: `url(${bg})` }}
      >
        
        <About />
        {/* <Marquee/> */}
        <OurProjects />
        {/* <Gallery2/> */}
        {/* <Gallery /> */}
        <CompanyParts/>
        {/* <Contact /> */}
        <img data-scroll data-scroll-section data-scroll-speed=".1" className="w-full " src={img} alt="our brands"/>
        
      </div>
    </div>
  );
}

export default Home;
