import React from "react";
import img_a from "./Card_img/Card_1.jpg";
import img_c from "./Card_img/Card_3.jpg";
import pic1 from "./Card_img/NewCard/ChailVillaLogo.png";

function Project1_Residential() {
  return (
    <div>
      <section className="flex flex-col justify-center gap-5 lg:gap-16 p-3">
        <h2 className="text-2xl md:text-4xl font-berkshire p-3 text-center bg-cover">
          Residential
        </h2>
      </section>

      {/* Project Cards */}
      <div className="flex flex-col gap-0">
        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 overflow-hidden p-3 m-auto gap-5 lg:gap-10">
          {/* Project 1 */}
          <a
            href="http://www.indusvalleymukteshwar.com"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-full md:h-96 md:w-80 lg:w-80 rounded-xl hover:shadow-lg hover:bg-[#E0BF8D] hover:text-[#08392F] bg-[#08392F] text-[#E0BF8D] shadow-xl transform transition-transform duration-500 hover:scale-95">
              <img className="rounded-t-xl w-full h-48 object-cover" src={img_a} alt="Card1" />
              <div className="mx-auto p-3 pt-5 md:pt-10 flex flex-col gap-3">
                <h2 className="text-xl md:text-2xl font-[yantramanav] font-semibold">
                  Indus Valley
                </h2>
                <p className="text-lg md:text-xl font-[yantramanav]">
                  Mukteshwar Nainital
                </p>
                <a
                  href="http://www.indusvalleymukteshwar.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="border-[#E0BF8D] hover:border-[#08392F] border-2 p-1 rounded-md text-sm md:text-lg">
                    www.indusvalleymukteshwar.com
                  </button>
                </a>
              </div>
            </div>
          </a>

          {/* Project 2 */}
          <a
            href="https://indusvalleyranikhet.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-full md:h-96 md:w-80 lg:w-80 rounded-xl hover:shadow-lg hover:bg-[#E0BF8D] hover:text-[#08392F] bg-[#08392F] text-[#E0BF8D] shadow-xl transform transition-transform duration-500 hover:scale-95">
              <img className="rounded-t-xl w-full h-48 object-cover" src={img_c} alt="Card1" />
              <div className="mx-auto p-3 pt-5 md:pt-10 flex flex-col gap-3">
                <h2 className="text-xl md:text-2xl font-[yantramanav] font-semibold">
                  Indus Valley
                </h2>
                <p className="text-lg md:text-xl font-[yantramanav]">
                  Ranikhet
                </p>
                <a
                  href="https://indusvalleyranikhet.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="border-[#E0BF8D] hover:border-[#08392F] border-2 p-1 rounded-md text-sm md:text-lg">
                    www.indusvalleyranikhet.com
                  </button>
                </a>
              </div>
            </div>
          </a>

          {/* Project 3 */}
          <a
            href="http://www.chailheightsvillas.com"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-full md:h-96 md:w-80 lg:w-80 rounded-xl hover:shadow-lg hover:bg-[#E0BF8D] hover:text-[#08392F] bg-[#08392F] text-[#E0BF8D] shadow-xl transform transition-transform duration-500 hover:scale-95">
              <img className="rounded-t-xl w-full h-48 object-cover" src={pic1} alt="Card1" />
              <div className="mx-auto p-3 pt-5 md:pt-10 flex flex-col gap-3">
                <h2 className="text-xl md:text-2xl font-[yantramanav] font-semibold">
                  Chail Heights Villas
                </h2>
                <p className="text-lg md:text-xl font-[yantramanav]">
                  Himachal Pradesh
                </p>
                <a
                  href="http://www.chailheightsvillas.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="border-[#E0BF8D] hover:border-[#08392F] border-2 p-1 rounded-md text-sm md:text-lg">
                    www.chailheightsvillas.com
                  </button>
                </a>
              </div>
            </div>
          </a>
        </section>
      </div>
    </div>
  );
}

export default Project1_Residential;
