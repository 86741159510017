import React from "react";
// import bg from "../../assets/photos/TSGOC_Website-page1.jpg"
// import bg5 from "./Background_img/bg5.avif";

function Investors() {
  return (
    <div
      className="p-4 flex flex-col py-10 md:pb-24 md:pt-10  gap-5 bg-cover bg-center bg-[#E0BF8D] bg-opacity-75"
      // data-scroll data-scroll-section data-scroll-speed=".2"
      // style={{ backgroundImage: `url(${bg})` }}
    >
      <h2 className="text-3xl md:text-5xl md:pt-5 font-berkshire text-center bg-cover">
        Investors
      </h2>

      <p className="text-lg md:text-2xl md:m-20 font-glacial md:my-5 px-1 text-justify">
      
Real estate investors have different views when it comes to the kinds of properties they focus on. While some investors may want to invest in residential properties or apartment complexes, others may prefer to purchase commercial real estate such as office spaces or malls. At TSGOC, we are focused on providing various types of benefits from our properties, such as property appreciation, development, and rental earnings.

      </p>
    </div>
  );
}

export default Investors;
