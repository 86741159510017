import React from "react";
import img_c from "./Card_img/Card_3.jpg";
import pic1 from "./Card_img/NewCard/resortLogo3.png";
import pic2 from "./Card_img/NewCard/ChailResortLogo.png";

const resorts = [
  {
    id: 1,
    name: "Indus Valley Resort",
    location: "Mukteshwar Nainital",
    imgSrc: pic1,
    website: "http://www.indusvalleyresort.com"
  },
  {
    id: 2,
    name: "Chail Heights Resort",
    location: "Chail Himachal Pradesh",
    imgSrc: pic2,
    website: "http://www.chailheightsresort.com"
  },
  {
    id: 3,
    name: "Ranikhet Resort",
    location: "Ranikhet Uttarakhand",
    imgSrc: img_c,
    website: "https://www.indusvalleyranikhet.com"
  }
];

function Project2_Resorts() {
  return (
    <div>
      <h2 className="text-2xl md:text-4xl font-berkshire p-3 my-auto text-center bg-cover">
        Upcoming Series Of Resorts
      </h2>
      <div className="flex flex-col gap-0">
        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:overflow-hidden gap-5 m-auto p-3 lg:gap-10">
          {resorts.map((resort) => (
            <a key={resort.id} href={resort.website} target="_blank" rel="noreferrer">
              <div className="w-full md:h-96 md:w-80 lg:w-80 rounded-xl hover:shadow-lg hover:bg-[#E0BF8D] hover:text-[#08392F] bg-[#08392F] text-[#E0BF8D] shadow-xl shadow-#E0BF8D transform transition-transform duration-500 hover:scale-95">
                <img className="rounded-t-xl w-full h-48 object-cover" src={resort.imgSrc} alt={resort.name} />
                <div className="mx-auto p-3 pt-5 md:pt-10 flex flex-col gap-3">
                  <h2 className="text-xl md:text-2xl font-[yantramanav] font-semibold">
                    {resort.name}
                  </h2>
                  <p className="text-lg md:text-xl font-[yantramanav]">
                    {resort.location}
                  </p>
                  <a href={resort.website} target="_blank" rel="noreferrer">
                    <button className="border-[#E0BF8D] hover:border-[#08392F] border-2 p-1 rounded-md text-sm md:text-lg">
                      {resort.website}
                    </button>
                  </a>
                </div>
              </div>
            </a>
          ))}
        </section>
      </div>
    </div>
  );
}

export default Project2_Resorts;
