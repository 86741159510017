import React from "react";
// import bg from "../../assets/photos/TSGOC_Website-page1.jpg"

function Associates() {
  const phoneNumber = "8826278989";
  return (
    <div
      className="p-4 flex flex-col py-10 md:pb-96 md:pt-20 gap-5 bg-[#E0BF8D] bg-opacity-75 bg-cover bg-center"
      // data-scroll data-scroll-section data-scroll-speed="-.2"
      // style={{ backgroundImage: `url(${bg})` }}
    >
      <h2 className="text-3xl md:text-5xl font-berkshire text-center bg-cover">
        Associates
      </h2>
      <p className="text-lg md:text-2xl md:m-20 md:my-4 mb-10 px-1 font-glacial text-justify">
      Greetings from TSGOC, where associates are an important part of our operations and are valued dearly. We believe in collaborations that last and are built on mutual confidence and openness. Your contributions and ideas are valuable to our organization's success, and we are certain that you and your contributions will be highly beneficial to its advancement.
        <br />
        If you might be interested in collaborating with us, kindly reach out to us. For any more details about our initiatives or if you have queries, you can get in touch with our executive, Mr. Rahul Aggarwal directly at  <a
          className=" text-xl hover:underline hover:text-blue-400 font-semibold"
          href={`tel:${phoneNumber}`}
        >
          8826278989
        </a>
      </p>
    </div>
  );
}
export default Associates;
