import React from "react";
// import bg from "../../assets/photos/TSGOC_Website-page1.jpg"

// import about_1 from "./AboutUs_img/about_1.webp";
// import about_2 from "./AboutUs_img/about_2.webp";
// import about_3 from "./AboutUs_img/about_3.webp";
// import about_4 from "./AboutUs_img/about_4.webp";

function About() {
  return (
    <>
      <div 
      // data-scroll data-scroll-section data-scroll-speed=".2"
        className="p-4 flex flex-col gap-5 md:pt-16 lg:pt-10 bg-[#E0BF8D] bg-opacity-75 bg-cover bg-center"
        // style={{ backgroundImage: `url(${bg})` }}
      >
        <h3 className="w-fit mx-auto text-3xl md:text-5xl py-5 pt-16 font-berkshire text-center p-3">
          About Us
        </h3>
        <div className="flex flex-col gap-4 justify-center items-center pb-10">
          <div className="flex flex-col gap-3">
            
           
            <p className="text-lg md:text-2xl md:mx-10 px-1 text-justify font-glacial">
            In 2009, TSGOC embarked on an extraordinary journey with a vision of creating homes surrounded by mother nature. Our incredibly committed team members are constantly focused on giving nature lovers the very best quality of service. With a growth rate of 200% in just a decade, TSGOC has successfully developed and completed its three real estate developments, Indus Valley Mukteshwar, and Ambika Divinity Haridwar. We aim at providing homes to those who cherish their golden years in the company of nature with a peaceful way of life and a break from the busy lifestyle of the big metropolis.
            </p>

            <div className="flex flex-col justify-center mx-auto py-10 text-center gap-5 md:gap-10">
              {/* <ol className="flex flex-col md:flex-row gap-5 md:gap-20" type="1">
                <li className="text-lg md:text-2xl font-sans hover:underline  ">
                  Shubham Build Up LTD
                </li>
                <li className="text-lg md:text-2xl font-sans hover:underline  ">
                  Shubham Buildtech LLP
                </li>
                <li className="text-lg md:text-2xl font-sans hover:underline  ">
                  Shubham Buildstone LLP
                </li>
                <li className="text-lg md:text-2xl font-sans hover:underline  ">
                  Shape My House
                </li>
              </ol> */}
              <ol className="flex flex-col md:flex-row gap-5 md:gap-16 " type="2">
                <li className="text-lg md:text-2xl font-sans hover:underline">
                  Chail Heights Villa LLP
                </li>
                <li className="text-lg md:text-2xl font-sans hover:underline md:ml-5">
                  Chail Heights Resort LLP
                </li>
                {/* <li className="text-lg md:text-2xl font-sans hover:underline md:ml-2 ">
                  Nirmanam Realtors LLP
                </li> */}
                  <li className="text-lg md:text-2xl font-sans hover:underline  ">
                  Shape My House
                </li>
                <li className="text-lg md:text-2xl font-sans hover:underline md:ml-8 ">
                  Neverland Resorts
                </li>
              </ol>
            </div>

            {/* <div className=" flex flex-col md:flex-row justify-center gap-5 my-5">
              <img
                className="shadow-lg shadow-green-100 w-fit md:w-1/5 rounded-xl md:hover:scale-75 md:duration-700"
                src={about_1}
                alt="img1"
              />
              <img
                className="shadow-lg shadow-green-100 w-fit md:w-1/5 rounded-xl md:hover:scale-75 md:duration-700"
                src={about_2}
                alt="img2"
              />
              <img
                className="shadow-lg shadow-green-100 w-fit md:w-1/5 rounded-xl md:hover:scale-75 md:duration-700"
                src={about_3}
                alt="img3"
              />
              <img
                className="shadow-lg shadow-green-100 w-fit md:w-1/5 rounded-xl md:hover:scale-75 md:duration-700"
                src={about_4}
                alt="img4"
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
