import React from 'react'
// import Vendors from './Vendors'
import Investors from './Investors'
// import Associates from './Associates'
import Associates2 from './Associates2'

function CompanyParts() {
  return (
    <div className='flex flex-col gap-3 md:gap-10'>
      {/* <Vendors/> */}
      <Investors/>
      <Associates2/>
    </div>
  )
}

export default CompanyParts
