import React from "react";

function MainHeading() {
  return (
    <div 
    // data-scroll data-scroll-speed=".2"
      className=" w-fit relative mx-auto"
    >
      <div className="absolute bg-[#75684f] bg-opacity-100 left-full rounded-3xl -translate-x-1/2 -translate-y-1/2 w-[70vw] top-1/2 p-1 md:p-2">
         <h1 className="text-yellow-400 text-lg text-center md:text-5xl lg:text-6xl mx-auto font-serif">
        TSGOC
      </h1>
      <p className=" text-sm text-white md:text-3xl lg:text-4xl mx-auto text-center font-serif m-2 mb-0 pb-0 px-2">
        Real Estate, Construction, Resorts and Collaboration
      </p>
      </div>
     
    </div>
  );
}

export default MainHeading;
 