import React, { useState } from "react";
import img_b from "./Card_img/Card_9.jpg";
import img_f from "./Card_img/Card_10.jpg";
import img2 from "./Card_img/Deliverd-Banner-Shimla.jpg";

function ProjectDelivered() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openFullscreenImage = (image) => {
    setSelectedImage(image);
  };

  const closeFullscreenImage = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <h2 className="text-2xl md:text-4xl font-berkshire p-3 my-auto text-center bg-cover">
        Delivered Projects
      </h2>
      <div className="flex flex-col gap-0">
        <section className="grid sm:grid-cols-1 md:grid-cols-2 gap-5 m-auto p-3 md:gap-20">
          {/* Card 1 */}
          <a
            href="https://www.ambikadivinityharidwar.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-full md:h-96 md:w-80 rounded-xl hover:shadow-lg bg-[#08392F] text-[#E0BF8D] hover:bg-[#E0BF8D] hover:text-[#08392F] md:m-3 shadow-xl shadow-zinc-700 transform transition-transform duration-500 hover:scale-95">
              <img className="rounded-t-xl w-full h-48 object-cover" src={img_b} alt="Card1" />
              <div className="mx-auto p-3 py-10 flex flex-col gap-3">
                <h2 className="text-xl md:text-2xl font-[yantramanav] font-semibold">
                  Ambika Suites
                </h2>
                <p className="text-lg md:text-xl font-[yantramanav]">
                  Shantikunj Haridwar
                </p>
              </div>
            </div>
          </a>
          {/* Card 2 */}
          <div
            className="w-full md:h-96 md:w-80 rounded-xl hover:shadow-lg bg-[#08392F] text-[#E0BF8D] hover:bg-[#E0BF8D] hover:text-[#08392F] md:m-3 shadow-xl shadow-zinc-700 transform transition-transform duration-500 hover:scale-95 cursor-pointer"
            onClick={() => openFullscreenImage(img2)}
          >
            <img className="rounded-t-xl w-full h-48 object-cover" src={img_f} alt="Card2" />
            <div className="mx-auto p-3 py-10 flex flex-col gap-3">
              <h2 className="text-xl md:text-2xl font-[yantramanav] font-semibold">
                Indus Valley Apartments
              </h2>
              <p className="text-lg md:text-xl font-[yantramanav]">Shimla</p>
            </div>
          </div>
        </section>
      </div>

      {/* Fullscreen Image */}
      {selectedImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 z-50 flex justify-center items-center" onClick={closeFullscreenImage}>
          <div className="max-w-full max-h-full p-4">
            <img src={selectedImage} alt="Fullscreen" className="max-w-full max-h-full" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectDelivered;
