// Import necessary dependencies
import React from "react";
// import bgContact from "./icons2/contact1.webp";
// import ContactForm from "./ContactForm";
// import CompanyDetail from "./CompanyDetail";
import ContactAddress from "./ContactAddress";

// Define the functional component for the form
const Contact = () => {
  return (
    <>
      {/* <CompanyDetail/> */}
      <div className="bg-cover bg-center bg-[#E0BF8D] bg-opacity-75 m-3 md:m-20 md:my-28 lg:my-20 rounded-2xl">
      <h2 className="animate__zoomIn text-4xl md:text-5xl text-center text-green-800 font-serif md:px-14 m-5 mt-0 pt-14 md:pt-10">
      Get in Touch and Let's Get Started...
      </h2>
      <p className="text-2xl md:text-3xl font-medium my-2 mx-5 text-center">
              (We are here to help you get the home of your dreams...)
            </p>

      <div className="flex flex-col md:flex-row justify-center md:p-5 md:mx-5 items-center"
      >
        <ContactAddress/>
        {/* <ContactForm/> */}
        <iframe className="text-3xl md:text-4xl w-full md:w-1/2 md:py-8 lg:px-20 h-96 text-white rounded-2xl font-semibold font-serif"
        title="crm-form"
        id="Contact_Form"
        src="https://account.solidperformers.com/capture_form_data/OTM3"
        border="0"

      ></iframe>
      </div>
      </div>
      
    </>
  );
};

// Export the component
export default Contact;
